import React, { useEffect, useState } from "react";
import "../../css/Public/landing.css"; // Import the CSS file
import serviceImg from "../../images/Our Services.png";
import workImg1 from "../../images/Working Process (1).png";
import workImg from "../../images/Working Process.png";
import workImg2 from "../../images/Working Process2.png";
import logo from "../../images/download 1 (1).png";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { sendUsersDemo } from "../../backend/Admin/UserManager/UserManager";
import { base_url } from "../../backend/config_backend";

export function Landing() {
  return (
    <div className="landing-container1">
      <Header />
      <div className="landing-images-container1">
        <div>
          <img src={serviceImg} alt="Our Services1" />
        </div>
        <div>
          <img src={workImg} alt="Working Process" />
        </div>
        <div>
          <img src={workImg1} alt="Working Process 1" />
        </div>
        <div>
            <UserPackages />
        </div>
        <div>
          <img src={workImg2} alt="Working Process 2" />
        </div>
      </div>
      <Footer />
    </div>
  );
}

const Header = () => (
  <div className="landing-header1">
    <div className="landing-header-left1">
      <img src={logo} alt="Logo" className="landing-logo1" />
      <div className="landing-menu1">
        <p>الصفحة الرئيسية</p>
        <p>خدمات خاصة</p>
        <p>وظائف</p>
        <p>اتصل بنا</p>
      </div>
    </div>
  </div>
);

const Footer = () => (
  <div className="landing-footer1">
    <div className="landing-footer-section">
      <div className="landing-footer-title">الصفحات</div>
      <div className="landing-footer-links">
        <div>الصفحة الرئيسية</div>
        <div>خدمات خاصة</div>
        <div>وظائف</div>
      </div>
    </div>
    <div className="landing-footer-section">
      <div className="landing-footer-title">اتصل بنا</div>
      <div className="landing-footer-links">
        <div>+۹۶۴-۱۱۱-۱۱۱-۱۱۱۱</div>
        <div>info@datakhana.com</div>
        <div>بغداد – شارع الصناعة</div>
      </div>
    </div>
  </div>
);

export const ServiceCard = ({ title, description }) => (
  <div className="landing-service-card">
    <div className="landing-card-icon"></div>
    <div className="landing-card-content">
      <div className="landing-card-title">{title}</div>
      <div className="landing-card-description">{description}</div>
    </div>
  </div>
);

export const ProcessCard = ({ title, subtitle, description, imageSrc }) => (
  <div className="landing-process-card">
    <div className="landing-process-card-content">
      <div className="landing-process-card-title">
        <span>{title}</span>
        <span className="landing-process-card-subtitle">{subtitle}</span>
      </div>
      <div className="landing-process-card-description">{description}</div>
    </div>
    <img className="landing-process-card-image" src={imageSrc} alt={title} />
  </div>
);


 function UserPackages() {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [feedback, setFeedback] = useState(""); // Feedback for user
  const navigate = useNavigate(); 

  useEffect(() => {
    axios
      .get(`${base_url}customUserPackages/packages/`)
      .then((response) => {
        setPackages(response.data.results);
        setLoading(false);
      })
      .catch(() => {
        setError("Failed to load packages");
        setLoading(false);
      });
  }, []);

  const handlePackageClick = async (packageName) => {
    setFeedback("Processing your request...");
    await sendUsersDemo(packageName);
    navigate("/demomessage");

  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="landing-packages-container">
      {feedback && <div className="feedback-message">{feedback}</div>}
      {packages.map((pkg) => (
        <div key={pkg.id} className="landing-package-card">
          <div className="landing-package-header">
            <span className="landing-package-name">{pkg.name}</span>
            <img
              className="landing-package-icon"
              src="https://via.placeholder.com/18x18"
              alt="icon"
            />
          </div>
          <div className="landing-package-price">
            <span className="landing-price-label">دینار/شهريا</span>
            <span className="landing-price-value">{pkg.price.toFixed(2)}</span>
          </div>
          <div className="landing-package-features">
            <div className="landing-feature-item">الوصول الكامل إلى المكتبة</div>
            <div className="landing-feature-item">مستخدم واحد</div>
            <div className="landing-feature-item">{pkg.search_limit} تقارير تحليلية</div>
            <div className="landing-feature-item">الدعم على مدار 24/7</div>
            <div className="landing-feature-item">ملف التعليمات</div>
          </div>
          
          <button
            className="landing-package-button"
            onClick={() => handlePackageClick(pkg.name)}
          >
            التسوق
          </button>
        </div>
      ))}
    </div>
  );
  }