import HomeMenu from "../../../pages/Public/HomeMenu";
// import MenuTop from "../../../components/SearchAll/Public/MenuTop";
import { ThemeContext, LanguageContext } from "../../../App";
import React, { useState, useContext, useEffect } from "react";
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
// } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  addFilterMaker,
  listFilterMaker,
  DeleteFilterMaker,
  editFilterMaker,
} from "../../../backend/filterMaker/FilterMaker_backend";
import { HashLoader } from "react-spinners";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import XIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MenuTop from "../Public/MenuTop";

function FilterMaker({
  saveLoginStatus,
  setIsSubmitted,
  isSuperUser,
  setSelectedMenuItem,
  idUserName,
}) {
  // dark mode
  const { theme } = useContext(ThemeContext);

  // arabic

  const { language } = useContext(LanguageContext);

  //   loaded list
  const [isLoading, setIsLoading] = useState(true);
  const [fetchedApi, setfetchedApi] = useState(false);
  const [apiData, setapiData] = useState([]);
  // const fetchDataRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await handleStart();
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedApi]);

  const handleStart = async () => {
    try {
      const result = await listFilterMaker();
      if (result.hasError) {
        if (result.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (result.status === 500) {
          if (language === "en") {
            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هذا المحور موجود حاليا في القائمة");
          }
        }
      } else {
        const data = result.data.data;
        console.log("🚀 filter maker result:", result);
        setapiData(data);
        setfetchedApi(true);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  useEffect(() => {
    const fetchDataRef = setInterval(() => {
      setfetchedApi(true);
    }, 5000);

    return () => {
      clearInterval(fetchDataRef);
    };
  }, []);

  const [showTable, setShowTable] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const handleFlashClick = (filterId) => {
    const selected = apiData.find((filter) => filter.id === filterId);
    setSelectedFilter(selected);
    setShowTable(false);
  };

  const handleReturnClick = () => {
    setShowTable(true);
  };

  const [showEdit, setShowEdit] = useState(true);

  const handleFlashClickEdit = () => {
    setShowEdit(false);

    if (selectedFilter && selectedFilter.name) {
      const filterNameInput = document.querySelector(
        ".header_filter_maker_add_input input"
      );
      if (filterNameInput) {
        filterNameInput.value = selectedFilter.name;
      }
    }
  };

  // const handleReturnClickEdit = () => {
  //   setShowEdit(true);
  // };

  // input filter

  const [inputValue, setInputValue] = useState("");
  const [wordList, setWordList] = useState([]);
  const [filterType, setFilterType] = useState("and");
  const [groupedWords, setGroupedWords] = useState([]);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isMenuOpen1, setMenuOpen1] = useState(false);
  const [filterType1, setFilterType1] = useState("and");


  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddWord = () => {
    if (inputValue.trim() !== "") {
      setWordList([...wordList,{"filter":filterType,"word":inputValue}]);
      setInputValue("");
    }
  };

  const handleRemoveWord = (index) => {
    const updatedWords = [...wordList];
    updatedWords.splice(index, 1);
    setWordList(updatedWords);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAddWord();
      handleAddWordEdit();
    }
  };

  const handleFilterChange = (value) => {
    // setFilterType(e.target.value);
    setFilterType(value);
    setMenuOpen(false);
  };
  const handleFilterChange1 = (value) => {
    // setFilterType(e.target.value);
    setFilterType1(value);
    setMenuOpen1(false);
  };
  const handleGrouping = () => {
    if (wordList.length > 0) {
      const newGroup = { type: filterType, words: wordList , groupFilter:filterType1 };
      const updatedGroupedWords = [...groupedWords, newGroup];
      setGroupedWords(updatedGroupedWords);
      setWordList([]);

      if (selectedFilter) {
        const updatedFilterText = updateFilterText(
          selectedFilter.filter_text,
          newGroup
        );
        setSelectedFilter({
          ...selectedFilter,
          filter_text: updatedFilterText,
        });
      }
    } else {
      if (language === "en") {
        Swal.fire({
          text: "Please Enter a Word...",
          icon: "warning",
          confirmButtonText:'OK'
        });

      } else {
        Swal.fire({
          text: "نجحت عملية الإضافة",
          icon: "warning",
          confirmButtonText:'حسناً'
        });
      }
    }
  };

  const updateFilterText = (filterText, newGroup) => {
    let updatedText = filterText;
    if (filterText) {
      updatedText += ` - `;
    }
    updatedText += formatGroupText(newGroup);
    return updatedText;
  };

  // const formatGroupText = (group) => {
  //   let groupText = "";
  //   group.words.forEach((word, index) => {
  //     groupText += `${word} `;
  //     if (index < group.words.length - 1) {
  //       groupText += getSeparator(group.type);
  //     }
  //   });
  //   return groupText.trim();
  // };

  const formatGroupText = (group) => {
    let groupText = "";
    group.words.forEach((word, index) => {
      groupText += `${word} `;
      if (index < group.words.length - 1) {
        groupText += getSeparator(group.type);
      }
    });
    if (group.type !== "no") {
      groupText += getSeparator(group.type);
    }
    return groupText.trim();
  };

  const formatFilterText = (groupedWords) => {
    let formattedText = "";

    groupedWords.forEach((group, groupIndex) => {
      const typeOfGrup = group.groupFilter;
      group.words.forEach((word, wordIndex) => {
        formattedText += word.word;
        if (wordIndex >=1) {
          formattedText += getSeparator(word.type);
        }
        if (wordIndex < group.words.length - 1) {
          formattedText += getSeparator(group.type);
        }
        console.log(group , typeOfGrup)
      });
      if (groupIndex < groupedWords.length - 1) {
        formattedText += ` ${getSeparator(typeOfGrup)}- `;
      }
    });
    return formattedText;
  };

  const getSeparator = (filterType) => {
    switch (filterType) {
      case "and":
        return "&";
      case "or":
        return "|";
      case "no":
        return "!";
      default:
        return "";
    }
  };

  const filteredWords = () => {
    console.log(wordList)
    return wordList.map((wordDic, index) => (
      <div key={index} className="filtered_word_box_filter_maker_page">
        <div className="filter_type_filter_maker_page_word_list">
          <div className="filter_type_filter_maker_page_word">{wordDic.word}</div>
          <button
            className="btn_delete_filtered_word_box_filter_maker_page"
            onClick={() => handleRemoveWord(index)}
          >
            <DeleteOutlineIcon />
          </button>
        </div>
        {index <= wordList.length - 1 && (
          <span className="filter_type_filter_maker_page">{wordDic.filter}</span>
        )}
      </div>
    ));
  };

  // const handleReturnClickEditAdd = () => {
  //   setShowEdit(true);
  //   setInputValue("");
  //   setWordList([]);
  //   setGroupedWords([]);
  // };

  // Edit Filter
  const [inputValueEdit, setInputValueEdit] = useState("");
  const [wordListEdit, setWordListEdit] = useState([]);
  const [filterTypeEdit, setFilterTypeEdit] = useState("and");
  const [groupedWordsEdit, setGroupedWordsEdit] = useState([]);
  const [isMenuOpenEdit, setMenuOpenEdit] = useState(false);

  const handleInputChangeEdit = (e) => {
    setInputValueEdit(e.target.value);
  };

  const handleAddWordEdit = () => {
    if (inputValueEdit.trim() !== "") {
      setWordListEdit([...wordListEdit, inputValueEdit]);
      setInputValueEdit("");
    }
  };

  const handleRemoveWordEdit = (index) => {
    const updatedWords = [...wordListEdit];
    updatedWords.splice(index, 1);
    setWordListEdit(updatedWords);
  };

  const handleFilterChangeEdit = (value) => {
    // setFilterType(e.target.value);
    setFilterTypeEdit(value);
    setMenuOpenEdit(false);
  };

  const handleGroupingEdit = () => {
    if (wordListEdit.length > 0) {
      const newGroup = { type: filterTypeEdit, words: wordListEdit };
      const updatedGroupedWords = [...groupedWordsEdit, newGroup];
      setGroupedWordsEdit(updatedGroupedWords);
      setWordListEdit([]);

      if (selectedFilter) {
        const updatedFilterText = updateFilterTextEdit(
          selectedFilter.filter_text,
          newGroup
        );
        setSelectedFilter({
          ...selectedFilter,
          filter_text: updatedFilterText,
        });
      }
    } else {
      if (language === "en") {
        Swal.fire({
          text: "Please Enter a Word...",
          icon: "warning",
          confirmButtonText:'OK'
        });

      } else {
        Swal.fire({
          text: "نجحت عملية الإضافة",
          icon: "warning",
          confirmButtonText:'حسناً'
        });
      }
    }
  };

  const updateFilterTextEdit = (filterText, newGroup) => {
    let updatedText = filterText;
    if (filterText) {
      updatedText += ` - `;
    }
    updatedText += formatGroupTextEdit(newGroup);
    return updatedText;
  };

  const formatGroupTextEdit = (group) => {
    let groupText = "";
    group.words.forEach((word, index) => {
      groupText += `${word} `;
      if (index < group.words.length - 1) {
        groupText += getSeparatorEdit(group.type);
      }
    });
    return groupText.trim();
  };

  // const formatFilterTextEdit = (groupedWords) => {
  //   let formattedText = "";

  //   groupedWords.forEach((group, groupIndex) => {
  //     group.words.forEach((word, wordIndex) => {
  //       formattedText += word;
  //       if (wordIndex < group.words.length - 1) {
  //         formattedText += getSeparatorEdit(group.type);
  //       }
  //     });
  //     if (groupIndex < groupedWords.length - 1) {
  //       formattedText += " - ";
  //     }
  //   });

  //   return formattedText;
  // };

  const getSeparatorEdit = (filterType) => {
    switch (filterType) {
      case "and":
        return "&";
      case "or":
        return "|";
      case "no":
        return "!";
      default:
        return "";
    }
  };

  const filteredWordsEdit = () => {
    return wordListEdit.map((word, index) => (
      <div key={index} className="filtered_word_box_filter_maker_page">
        <div className="filter_type_filter_maker_page_word_list">
          <div className="filter_type_filter_maker_page_word">{word}</div>
          <button
            className="btn_delete_filtered_word_box_filter_maker_page"
            onClick={() => handleRemoveWordEdit(index)}
          >
            <DeleteOutlineIcon />
          </button>
        </div>
        {index < wordListEdit.length - 1 && (
          <span className="filter_type_filter_maker_page">
            {filterTypeEdit}
          </span>
        )}
      </div>
    ));
  };

  const handleReturnClickEdit = () => {
    setShowEdit(true);
    setInputValueEdit("");
    setWordListEdit([]);
    setGroupedWordsEdit([]);
  };

  // حذف گروه

  const handleRemoveGroupEdit = (groupIndex) => {
    const updatedGroups = selectedFilter.filter_text.split(" - ");
    updatedGroups.splice(groupIndex, 1);
    const updatedFilterText = updatedGroups.join(" - ");
    setSelectedFilter({ ...selectedFilter, filter_text: updatedFilterText });
  };

  const handleRemoveGroup = (groupIndex) => {
    const updatedGroupedWords = [...groupedWords];
    updatedGroupedWords.splice(groupIndex, 1);
    setGroupedWords(updatedGroupedWords);
  };

  // tab simple and advance

  const [isSimpleActive, setIsSimpleActive] = useState(false);
  const [isAdvanceActive, setIsAdvanceActive] = useState(true);

  const handleFlashClickAdvance = (filterId) => {
    setIsSimpleActive(true);
    const filteredData = apiData.filter((filter) => filter.id === filterId);
    console.log("Filtered Data:", filteredData);
    setIsAdvanceActive(false);
  };

  const handleReturnClickAdvance = () => {
    setIsSimpleActive(false);
    setIsAdvanceActive(true);
  };

  //   send form

  const handleSendFilter = async () => {
    try {
      const formattedFilterText = formatFilterText(groupedWords);
      console.log(formattedFilterText)
      const filterName = document.querySelector(
        "#filter_name_input_send"
      ).value;

      console.log("filterNamefilterName", filterName);

      if (!filterName.trim()) {
        if (language === "ar") {
          toast.error("يرجى ملء حقل الاسم");
        } else {
          toast.error("Please fill in the name field");
        }
        return;
      }
      if (groupedWords.length === 0) {
        if (language === "ar") {
          toast.error("أضف على الأقل مجموعة واحدة");
        } else {
          toast.error("Please add at least one group");
        }
        return;
      }

      const result = await addFilterMaker(formattedFilterText, filterName);

      console.log("Got Users ", new Date());

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (result.status === 500) {
          if (language === "ar") {
          toast.error("Server Error");
        } else {
          toast.error("هناك خطأ في الاتصال");
        }
      }
      } else {
        if (language === "ar") {
          toast.success("تم إنشاء فلتر جديد بنجاح");
        } else {
          toast.success("New filter created successfully");
        }
        await handleStart();
        setWordList([]);
        setGroupedWords([]);
        toggleSectionAdd();
      }
    } catch (error) {
      if (language === "ar") {
        toast.error("حدث خطأ ما");
      } else {
        toast.error("Something Went Wrong");
      }
    }
  };

  //   delete

  const handleDeleteClick = async (filterId) => {
    try {
      const confirmDelete = await Swal.fire({
        title: language === "ar" ? "هل أنت متأكد؟" : "Are you sure?",
        text:
          language === "ar"
            ? "لن تتمكن من التراجع عن هذا!"
            : "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText:
          language === "ar" ? "!نعم، احذفها" : "Yes, delete it!",
        cancelButtonText: language === "ar" ? "لا، الغاء" : "No, Cancel",
      });

      if (confirmDelete.isConfirmed) {
        setIsLoading(true);
        const result = await DeleteFilterMaker(filterId);
        if (!result.hasError) {
          toast.success(
            language === "ar"
              ? "تم حذف الفلتر بنجاح"
              : "Filter deleted successfully"
          );
          await handleStart();
        } else {
          toast.error(
            language === "ar"
              ? "حدث خطأ أثناء حذف الفلتر"
              : "Error deleting filter"
          );
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.error(
        language === "ar"
          ? "حدث خطأ أثناء حذف الفلتر:"
          : "Error deleting filter:",
        error.message
      );
    }
  };

  // show section add

  const [showSectionAdd, setShowSectionAdd] = useState(false);

  const toggleSectionAdd = () => {
    setShowSectionAdd(!showSectionAdd);
    if (!showSectionAdd) {
      setInputValue("");
      setWordList([]);
      setGroupedWords([]);
    }
  };

  const handleSaveEditFilter = async () => {
    try {
      // const formattedFilterText = formatFilterText(groupedWords);
      const filterId = selectedFilter.id;

      const filterName = document.querySelector(
        "#filter_name_input_edit"
      ).value;

      const nameToSend = filterName.trim() ? filterName : selectedFilter.name;

      let allGroups = selectedFilter.filter_text.split(" - ");
      if (Array.isArray(groupedWordsEdit) && groupedWordsEdit.length > 0) {
        groupedWordsEdit.forEach((word) => {
          if (typeof word === "string") {
            allGroups.push(word);
          }
        });
      }

      const result = await editFilterMaker(
        filterId,
        allGroups.join(" - "),
        nameToSend
      );

      if (result.hasError) {
        if (result.isLoggedIn === false) {
          // setneedLogin(true);
        } else if (result.status === 500) {
          if(language === 'en') {

            toast.error("Server Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        } else {
          if (language === "en") {
            toast.error("Connection Error");
          } else {
            toast.error("هناك خطأ في الاتصال");
          }
        }
      } else {
        if(language === 'en') {

          toast.success("Success add");
        }else {
          toast.success("نجحت عملية الإضافة");
        }
        await handleStart();
      }
    } catch (error) {
      if (language === "ar") {
        toast.error("حدث خطأ ما");
      } else {
        toast.error("Something Went Wrong");
      }
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isMenuOpen &&
        !event.target.closest(
          ".dropdown_input_container_result_body_filter_maker_information_filter_add"
        )
      ) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isMenuOpenEdit &&
        !event.target.closest(
          ".dropdown_input_container_result_body_filter_maker_information_filter_add"
        )
      ) {
        setMenuOpenEdit(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isMenuOpenEdit]);

  return (
    <div className="search_all">
      <div
        className={`search_all_page ${
          language === "ar" ? "search_all_page_ar" : ""
        } ${theme === "dark" ? "dark_mode" : ""}`}
      >
        <HomeMenu
          saveLoginStatus={saveLoginStatus}
          setIsSubmitted={setIsSubmitted}
          isSuperUser={isSuperUser}
          isDefultDB={isSuperUser}
        />
        <div
          className={`body_search_all ${theme === "dark" ? "dark_mode" : ""}`}
        >
          <div className="header_search_all">
            <MenuTop
              onSelectMenuItem={setSelectedMenuItem}
              username={idUserName}
              saveLoginStatus={saveLoginStatus}
              setIsSubmitted={setIsSubmitted}
            />
          </div>
          <div className="header_filter_maker">
            {language === "ar" ? (
              <div className="header_filter_maker_body">
                {!showSectionAdd ? (
                  <button
                    id="btn_add_filter_header_filter_page"
                    onClick={toggleSectionAdd}
                  >
                    <AddIcon />
                  </button>
                ) : (
                  <div className="header_filter_maker_add_btn_close_add">
                    <button onClick={toggleSectionAdd}>
                      <CloseIcon />
                    </button>
                  </div>
                )}
                <p>انشاء استعلام</p>
              </div>
            ) : (
              <div className="header_filter_maker_body_en">
                {!showSectionAdd ? (
                  <button
                    id="btn_add_filter_header_filter_page"
                    onClick={toggleSectionAdd}
                  >
                    <AddIcon />
                  </button>
                ) : (
                  <div className="header_filter_maker_add_btn_close_add">
                    <button onClick={toggleSectionAdd}>
                      <CloseIcon />
                    </button>
                  </div>
                )}
                <p id="header_filter_maker_body_en_id_p">Manage Filters</p>
              </div>
            )}

            {showSectionAdd && (
              <>
                {language === "ar" ? (
                  <div className="header_filter_maker_add en">
                  <p style={{alignSelf:"flex-end"}}>add filter</p>
                    <div style={{ width: "90%", height: 70, justifyContent: 'flex-start', alignItems: 'center', gap: 24, display: 'inline-flex' }}>
                      <div style={{ flex: '1 1 0', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 8, display: 'inline-flex' }}>
                        <div style={{ paddingLeft: 8, paddingRight: 8, justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex' }}>
                          <div style={{ color: '#C3C7DA', fontSize: 12, fontFamily: 'SF Pro Display', fontWeight: '500', wordWrap: 'break-word' }}>عنوان الاستعلام</div>
                        </div>
                        <input   name="filter_name"
                            placeholder="أدخل الاسم"
                            type="text"
                            id="filter_name_input_send"  style={{ alignSelf: 'stretch', height: 48, paddingTop: 10, paddingBottom: 10, paddingLeft: 16, paddingRight: 8, background: 'white', borderRadius: 12, border: '0.40px #C3C7DA solid', justifyContent: 'flex-start', alignItems: 'center', gap: 10, display: 'inline-flex' }}>
                        </input>
                      </div>
                      <div style={{ flex: '1 1 0', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 8, display: 'inline-flex' }}>
                        <div style={{ paddingLeft: 8, paddingRight: 8, justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex' }}>
                          <div style={{ color: '#C3C7DA', fontSize: 12, fontFamily: 'SF Pro Display', fontWeight: '500', wordWrap: 'break-word' }}>Words</div>
                        </div>
                        <div style={{position:"relative"}}>
                        <input   // required
                            value={inputValue}
                            placeholder="أدخل كلمة"
                            onChange={handleInputChange}
                            onKeyPress={handleKeyPress}
                            type="text"  style={{ alignSelf: 'stretch' , width:"300px", height: 48, paddingTop: 10, paddingBottom: 10, paddingLeft: 16, paddingRight: 8, background: 'white', borderRadius: 12, border: '0.40px #C3C7DA solid', justifyContent: 'space-between', alignItems: 'center', display: 'inline-flex' }}> 
                        </input>
                        <button     onClick={handleAddWord} style={{ width: 57, position:"absolute" , left:"5px" , top:"5px", paddingLeft: 16, paddingRight: 16 , paddingBlock:10, background: '#458BDF', borderRadius: 8, justifyContent: 'center', alignItems: 'center', gap: 8, display: 'flex' }}>
                            <div style={{ color: 'white', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '600', wordWrap: 'break-word' }}>Add</div>
                          </button>
                        </div>
                      </div>
                      <div style={{display:"flex" , flexDirection:"column"}}>
                      <label>بین الکلمات</label>

                      <div className="dropdown_input_container_result_body_filter_maker_information_filter_add" style={{width:"100%"}}>
                        
                        <div
                          className="dropdown_button_input_container_result_body_filter_maker_information_filter_add"
                          onClick={() => setMenuOpen(!isMenuOpen)}
                        >
                          <span
                            className={`arrow_filter_maker_menu_arabic ${
                              isMenuOpen ? "up" : "down"
                            }`}
                          >
                            <KeyboardArrowDownIcon id="arrow_down_menu_filter_maker" />
                          </span>
                          {filterType === "and"
                            ? "and"
                            : filterType === "or"
                            ? "or"
                            : "not"}
                        </div>
                        {isMenuOpen && (
                          <div style={{zIndex:'100'}} className="dropdown_menu_filter_maker_menu">
                            <div onClick={() => handleFilterChange("and")}>
                              And
                            </div>
                            <div onClick={() => handleFilterChange("or")}>
                              Or
                            </div>
                            <div onClick={() => handleFilterChange("no")}>
                              Not
                            </div>
                          </div>
                        )}
                      </div>
                      </div>
                    </div>
                    <div className="rows_input_container_result_body_filter_maker_information_filter_add">
                      <div className="words_input_group_header_filter_maker_add_items">
                 
                      </div>
                      <button
                        className="button_input_group_filter_maker_page_add arabic"
                        onClick={handleGrouping}
                      >
                        إضافة مجموعة
                      </button>
                      <div style={{display:"flex" , flexDirection:"column"}}>
                      <label>بین المجموعه</label>
                      <div style={{width:"100%"}} className="dropdown_input_container_result_body_filter_maker_information_filter_add">
                        <div
                          className="dropdown_button_input_container_result_body_filter_maker_information_filter_add"
                          onClick={() => setMenuOpen1(!isMenuOpen1)}
                        >
                          <span
                            className={`arrow_filter_maker_menu_arabic ${
                              isMenuOpen1 ? "up" : "down"
                            }`}
                          >
                            <KeyboardArrowDownIcon id="arrow_down_menu_filter_maker" />
                          </span>

                          {filterType1 === "and"
                            ? "and"
                            : filterType1 === "or"
                            ? "or"
                            : "not"}
                        </div>
                        {isMenuOpen1 && (
                          <div style={{zIndex:'100'}} className="dropdown_menu_filter_maker_menu">
                            <div onClick={() => handleFilterChange1("and")}>
                              And
                            </div>
                            <div onClick={() => handleFilterChange1("or")}>
                              Or
                            </div>
                            <div onClick={() => handleFilterChange1("no")}>
                              Not
                            </div>
                          </div>
                        )}
                      </div>
</div>

                   
                    </div>

                    <div className="rows_input_container_result_body_filter_maker_information_filter_add">
                      <div className="word_list_filter_maker_page">
                        {filteredWords()}
                      </div>
                    </div>
                    <div className="grouped_words_filter_maker_page">
                      {/* <p>Added Groups : </p> */}
                      {groupedWords.map((group, groupIndex) => (
                        <React.Fragment key={groupIndex}>
                          <div className="filter_type_filter_maker_page_word_list_group">
                            <div
                              key={groupIndex}
                              className="group_grouped_words_filter_maker_page"
                            >
                              {group.words.map((wordDict, wordIndex) => (
                                <div key={wordIndex} className="word_in_group">
                                  <div className="filter_type_filter_maker_page_word_new">
                                    {wordDict.word}
                                  </div>
                                  {wordIndex < group.words.length - 1 && (
                                    <span className="filter_type_filter_maker_page">
                                      {wordDict.type}
                                    </span>
                                  )}
                                </div>
                              ))}
                            </div>
                            <button
                              // id="btn_add_filter_header_filter_page_red"
                              className="btn_delete_filtered_word_box_filter_maker_page_group"
                              onClick={() => handleRemoveGroup(groupIndex)}
                            >
                              <DeleteOutlineIcon />
                            </button>
                          </div>
                          {groupIndex < groupedWords.length - 1 && (
                            <div className="word_in_group_new">{getSeparatorEdit(group.groupFilter)}</div>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                    <div className="bottom_header_filter_maker_add arabic">
                      <button
                        onClick={handleSendFilter}
                        className="button_input_group_filter_maker_page_add arabic"
                      >
                        إرسال
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="header_filter_maker_add en">
                    <div className="rows_input_container_result_body_filter_maker_information_filter_add en">
                      <div className="words_input_group_header_filter_maker_add_items">
                        <div className="input_group_header_filter_maker_add">
                          <input
                            name="filter_name"
                            placeholder="Enter Name"
                            type="text"
                            id="filter_name_input_send"
                          />
                          <span>Name Filter</span>
                        </div>
                      </div>

                      <div className="words_input_group_header_filter_maker_add_items">
                        <button
                          onClick={handleAddWord}
                          className="button__submit_input_group_header_filter_maker_add_input tooltip_add_filter"
                        >
                          <CheckIcon />
                          <span className="tooltip_text_add_filter_page">
                            Press Enter button key
                          </span>
                        </button>

                        <div className="input_group_header_filter_maker_add">
                          <input
                            // required
                            value={inputValue}
                            placeholder="Enter Word"
                            onChange={handleInputChange}
                            onKeyPress={handleKeyPress}
                            type="text"
                          />
                          <span>Words</span>
                        </div>
                      </div>

                      <div className="dropdown_input_container_result_body_filter_maker_information_filter_add">
                        <div
                          className="dropdown_button_input_container_result_body_filter_maker_information_filter_add en"
                          onClick={() => setMenuOpen(!isMenuOpen)}
                        >
                          <span
                            className={`arrow_filter_maker_menu ${
                              isMenuOpen ? "up" : "down"
                            }`}
                          >
                            <KeyboardArrowDownIcon id="arrow_down_menu_filter_maker en" />
                          </span>

                          {filterType === "and"
                            ? "And"
                            : filterType === "or"
                            ? "Or"
                            : "Not"}
                        </div>
                        {isMenuOpen && (
                          <div className="dropdown_menu_filter_maker_menu en">
                            <div onClick={() => handleFilterChange("and")}>
                              And
                            </div>
                            <div onClick={() => handleFilterChange("or")}>
                              Or
                            </div>
                            <div onClick={() => handleFilterChange("no")}>
                              Not
                            </div>
                          </div>
                        )}
                      </div>

                      <button
                        className="button_input_group_filter_maker_page_add"
                        onClick={handleGrouping}
                      >
                        ADD Group
                      </button>
                    </div>

                    <div className="rows_input_container_result_body_filter_maker_information_filter_add en">
                      <div className="word_list_filter_maker_page en">
                        {filteredWords()}
                      </div>
                    </div>
                    <div className="grouped_words_filter_maker_page en">
                      {/* <p>Added Groups : </p> */}
                      {groupedWords.map((group, groupIndex) => (
                        <React.Fragment key={groupIndex}>
                          <div className="filter_type_filter_maker_page_word_list_group">
                            <div
                              key={groupIndex}
                              className="group_grouped_words_filter_maker_page"
                            >
                              {group.words.map((word, wordIndex) => (
                                <div key={wordIndex} className="word_in_group">
                                  <div className="filter_type_filter_maker_page_word_new">
                                    {word}
                                  </div>
                                  {wordIndex < group.words.length - 1 && (
                                    <span className="filter_type_filter_maker_page">
                                      {group.type}
                                    </span>
                                  )}
                                </div>
                              ))}
                            </div>
                            <button
                              // id="btn_add_filter_header_filter_page_red"
                              className="btn_delete_filtered_word_box_filter_maker_page_group"
                              onClick={() => handleRemoveGroup(groupIndex)}
                            >
                              <DeleteOutlineIcon />
                            </button>
                          </div>
                          {groupIndex < groupedWords.length - 1 && (
                            <div className="word_in_group_new">&</div>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                    <div className="bottom_header_filter_maker_add">
                      <button
                        onClick={handleSendFilter}
                        className="button_input_group_filter_maker_page_add en"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="body_filter_maker">
            {showTable ? (
              <div className="container_table_body_filter_maker">
                {language === "ar" ? (
                  <ul className="responsive_table_filter_maker">
                    <li
                      className="table_header_filter_maker arabic"
                      id="table_header_filter_maker_arabic"
                    >
                      <div className="col col-1">التسلسل</div>
                      <div className="col col-2">الاسم</div>
                      <div className="col col-3">تاريخ الإنشاء</div>
                      <div className="col col-5">الشبكات الإجتماعية</div>
                      <div className="col col-6">خيارات</div>
                    </li>
                    {apiData.length === 0 ? (
                      <div className="not_selected_btn_request_massage">
                        <p>أنت لم تصنع الفلتر بعد</p>
                      </div>
                    ) : (
                      <>
                        {isLoading ? (
                          <div className="spinner_postbox_admin_page">
                            <div>
                              <HashLoader color="#458BDF" size={50} />
                            </div>
                          </div>
                        ) : (
                          <>
                            {apiData.map((filter, index) => (
                              <li
                                className="table_row_filter_maker arabic"
                                id="table_row_filter_maker_arabic"
                                key={filter.id}
                              >
                                <div className="col col-1" data-label="ID">
                                  {index + 1}
                                </div>
                                <div className="col col-2" data-label="Name">
                                  {filter.name}
                                </div>
                                <div
                                  className="col col-3"
                                  data-label="Create Date"
                                >
                                  {formatDate(filter.updated_at)}
                                </div>
                                <div className="col col-5" data-label="Socials">
                                  <XIcon id="social_media_icon_filter_maker" />
                                  <FacebookIcon id="social_media_icon_filter_maker" />
                                  <InstagramIcon id="social_media_icon_filter_maker" />
                                </div>
                                <div className="col col-6" data-label="Options">
                                  <CloseIcon
                                    id="options_btn_filter_maker_page"
                                    onClick={() => handleDeleteClick(filter.id)}
                                  />
                                  <ArrowBackIosIcon
                                    id="options_btn_filter_maker_page"
                                    onClick={() => handleFlashClick(filter.id)}
                                  />
                                </div>
                              </li>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </ul>
                ) : (
                  <ul className="responsive_table_filter_maker">
                    <li
                      className="table_header_filter_maker"
                      id="table_header_filter_maker_en"
                    >
                      <div className="col col-1">Row</div>
                      <div className="col col-2">Name</div>
                      <div className="col col-3">Create Date</div>
                      <div className="col col-5">Socials</div>
                      <div className="col col-6">Options</div>
                    </li>
                    {apiData.length === 0 ? (
                      <div className="not_selected_btn_request_massage">
                        <p>You haven't built a filter yet.</p>
                      </div>
                    ) : (
                      <>
                        {isLoading ? (
                          <div className="spinner_postbox_admin_page">
                            <div>
                              <HashLoader color="#458BDF" size={50} />
                            </div>
                          </div>
                        ) : (
                          <>
                            {apiData.map((filter, index) => (
                              <li
                                className="table_row_filter_maker"
                                id="table_row_filter_maker_en"
                                key={filter.id}
                              >
                                <div className="col col-1" data-label="ID">
                                  {index + 1}
                                </div>
                                <div className="col col-2" data-label="Name">
                                  {filter.name}
                                </div>
                                <div
                                  className="col col-3"
                                  data-label="Create Date"
                                >
                                  {formatDate(filter.updated_at)}
                                </div>
                                <div className="col col-5" data-label="Socials">
                                  <XIcon id="social_media_icon_filter_maker" />
                                  <FacebookIcon id="social_media_icon_filter_maker" />
                                  <InstagramIcon id="social_media_icon_filter_maker" />
                                </div>
                                <div className="col col-6" data-label="Options">
                                  <CloseIcon
                                    id="options_btn_filter_maker_page"
                                    onClick={() => handleDeleteClick(filter.id)}
                                  />
                                  <ArrowForwardIosIcon
                                    id="options_btn_filter_maker_page"
                                    onClick={() => handleFlashClick(filter.id)}
                                  />
                                </div>
                              </li>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </ul>
                )}
              </div>
            ) : (
              <div className="result_body_filter_maker">
                <div className="btn_return_result_body_filter_maker">
                  <button>
                    <ArrowBackIosNewIcon onClick={handleReturnClick} />
                  </button>
                </div>
                {selectedFilter && (
                  <>
                    <div className="result_body_filter_maker_information">
                      {language === "ar" ? (
                        <div className="items_result_body_filter_maker_information_header">
                          <p>تصفية الكلمات</p>
                        </div>
                      ) : (
                        <div className="items_result_body_filter_maker_information_header_en">
                          <p>Filter Word</p>
                        </div>
                      )}
                      {showEdit ? (
                        <div className="items_result_body_filter_maker_information_left_down">
                          {language === "ar" ? (
                            <div className="items_result_body_filter_maker_information_left_down_btn_edit">
                              <EditIcon
                                onClick={handleFlashClickEdit}
                                id="options_btn_filter_maker_page"
                              />
                            </div>
                          ) : (
                            <div className="items_result_body_filter_maker_information_left_down_btn_edit en">
                              <EditIcon
                                onClick={handleFlashClickEdit}
                                id="options_btn_filter_maker_page"
                              />
                            </div>
                          )}
                          <div className="items_result_body_filter_maker_information_row_down">
                            {language === "ar" ? (
                              <div className="grouped_words_filter_maker_page">
                                {selectedFilter.filter_text
                                  .split(" - ")
                                  .map((group, groupIndex, groups) => (
                                    
                                    <React.Fragment key={groupIndex}>
                          
                                      <div className="group_grouped_words_filter_maker_page_detail">
                                        {group
                                          .split('-')
                                          .map((word, wordIndex, arr) => {
                                            const groupFilterType = word.includes("|")
                                            return (
                                              <div
                                                key={wordIndex}
                                                className="word_in_group"
                                              >
                                                <div className="filter_type_filter_maker_page_word_detail">
                                                  {word}
                                                </div>
                                                {wordIndex < arr.length - 1 && (
                                                  <span className="filter_type_filter_maker_page_detail">
                                                    {groupFilterType}
                                                  </span>
                                                )}
                                              </div>
                                            );
                                          })}
                                      </div>
                                      {groupIndex < groups.length - 1 && (
                                        <span className="word_in_group_new">
                                          And
                                        </span>
                                      )}
                                    </React.Fragment>
                                  ))}
                              </div>
                            ) : (
                              <div className="grouped_words_filter_maker_page">
                                {selectedFilter.filter_text
                                  .split(" - ")
                                  .map((group, groupIndex, groups) => (
                                    <React.Fragment key={groupIndex}>
                                      <div className="group_grouped_words_filter_maker_page_detail">
                                        {group
                                          .split(/&|\||!/)
                                          .map((word, wordIndex, arr) => {
                                            const groupFilterType =
                                              group.includes("&")
                                                ? "And"
                                                : group.includes("|")
                                                ? "Or"
                                                : "Not";
                                            return (
                                              <div
                                                key={wordIndex}
                                                className="word_in_group"
                                              >
                                                <div className="filter_type_filter_maker_page_word_detail">
                                                  {word}
                                                </div>
                                                {wordIndex < arr.length - 1 && (
                                                  <span className="filter_type_filter_maker_page_detail">
                                                    {groupFilterType}
                                                  </span>
                                                )}
                                              </div>
                                            );
                                          })}
                                      </div>
                                      {groupIndex < groups.length - 1 && (
                                        <span className="word_in_group_new">
                                          And
                                        </span>
                                      )}
                                    </React.Fragment>
                                  ))}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="result_body_filter_maker_information_filter_add">
                          {language === "ar" ? (
                            <div className="items_result_body_filter_maker_information_header">
                              <div className="items_result_body_filter_maker_information_header_down_right">
                                <div
                                  className={`items_result_body_filter_maker_information_header_down_right ${
                                    isSimpleActive ? "active" : ""
                                  }`}
                                >
                                  <button onClick={handleFlashClickAdvance}>
                                    بسيط
                                  </button>
                                </div>
                                <div
                                  className={`items_result_body_filter_maker_information_header_down_right ${
                                    isAdvanceActive ? "active" : ""
                                  }`}
                                >
                                  <button onClick={handleReturnClickAdvance}>
                                    متقدم
                                  </button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="items_result_body_filter_maker_information_header en">
                              <div className="items_result_body_filter_maker_information_header_down_right">
                                <div
                                  className={`items_result_body_filter_maker_information_header_down_right ${
                                    isSimpleActive ? "active" : ""
                                  }`}
                                >
                                  <button onClick={handleFlashClickAdvance}>
                                    Simple
                                  </button>
                                </div>
                                <div
                                  className={`items_result_body_filter_maker_information_header_down_right ${
                                    isAdvanceActive ? "active" : ""
                                  }`}
                                >
                                  <button onClick={handleReturnClickAdvance}>
                                    Advanced
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                          {isAdvanceActive ? (
                            <>
                              {language === "ar" ? (
                                <div className="input_container_result_body_filter_maker_information_filter_add en">
                                  <div className="rows_input_container_result_body_filter_maker_information_filter_add">
                                    <div className="words_input_group_header_filter_maker_add_items">
                                      <div className="input_group_header_filter_maker_add arabic">
                                        <input
                                          name="filter_name"
                                          placeholder="أدخل الاسم"
                                          type="text"
                                          id="filter_name_input_edit"
                                        />
                                        <span>عنوان استعلام</span>
                                      </div>
                                    </div>

                                    <div className="words_input_group_header_filter_maker_add_items">
                                      <div className="input_group_header_filter_maker_add arabic">
                                        <input
                                          // required
                                          value={inputValueEdit}
                                          placeholder="أدخل كلمة"
                                          onChange={handleInputChangeEdit}
                                          onKeyPress={handleKeyPress}
                                          type="text"
                                        />
                                        <span>كلمات</span>
                                      </div>
                                      <button
                                        onClick={handleAddWordEdit}
                                        className="button__submit_input_group_header_filter_maker_add_input tooltip_add_filter"
                                      >
                                        <CheckIcon />
                                        <span className="tooltip_text_add_filter_page">
                                          اضغط على مفتاح زر الإدخال
                                        </span>
                                      </button>
                                    </div>

                                    <div className="dropdown_input_container_result_body_filter_maker_information_filter_add">
                                      <div
                                        className="dropdown_button_input_container_result_body_filter_maker_information_filter_add"
                                        onClick={() =>
                                          setMenuOpenEdit(!isMenuOpenEdit)
                                        }
                                      >
                                        <span
                                          className={`arrow_filter_maker_menu_arabic ${
                                            isMenuOpenEdit ? "up" : "down"
                                          }`}
                                        >
                                          <KeyboardArrowDownIcon id="arrow_down_menu_filter_maker" />
                                        </span>

                                        {filterTypeEdit === "and"
                                          ? "and"
                                          : filterTypeEdit === "or"
                                          ? "or"
                                          : "not"}
                                      </div>
                                      {isMenuOpenEdit && (
                                        <div className="dropdown_menu_filter_maker_menu">
                                          <div
                                            onClick={() =>
                                              handleFilterChangeEdit("and")
                                            }
                                          >
                                            And
                                          </div>
                                          <div
                                            onClick={() =>
                                              handleFilterChangeEdit("or")
                                            }
                                          >
                                            Or
                                          </div>
                                          <div
                                            onClick={() =>
                                              handleFilterChangeEdit("no")
                                            }
                                          >
                                            Not
                                          </div>
                                        </div>
                                      )}
                                    </div>

                                    <button
                                      className="button_input_group_filter_maker_page_add arabic"
                                      onClick={handleGroupingEdit}
                                    >
                                      إضافة مجموعة
                                    </button>
                                  </div>

                                  <div className="rows_input_container_result_body_filter_maker_information_filter_add">
                                    <div className="word_list_filter_maker_page">
                                      {filteredWordsEdit()}
                                    </div>
                                  </div>
                                  <div className="grouped_words_filter_maker_page">
                                    {selectedFilter.filter_text
                                      .split(" - ")
                                      .map((group, groupIndex, groups) => (
                                        <React.Fragment key={groupIndex}>
                                          <div className="filter_type_filter_maker_page_word_list_group">
                                            <button
                                              // id="btn_add_filter_header_filter_page_red"
                                              className="btn_delete_filtered_word_box_filter_maker_page_group"
                                              onClick={() =>
                                                handleRemoveGroupEdit(
                                                  groupIndex
                                                )
                                              }
                                            >
                                              <DeleteOutlineIcon />
                                            </button>
                                            {group
                                              .split(/&|\||!/)
                                              .map((word, wordIndex, arr) => {
                                                const groupFilterType =
                                                  group.includes("&")
                                                    ? "and"
                                                    : group.includes("|")
                                                    ? "or"
                                                    : "not";
                                                return (
                                                  <div
                                                    key={wordIndex}
                                                    className="group_grouped_words_filter_maker_page"
                                                  >
                                                    <div className="filter_type_filter_maker_page_word_new">
                                                      {word}
                                                    </div>
                                                    {wordIndex <
                                                      arr.length - 1 && (
                                                      <span className="filter_type_filter_maker_page">
                                                        {groupFilterType}
                                                      </span>
                                                    )}
                                                  </div>
                                                );
                                              })}
                                          </div>
                                          {groupIndex < groups.length - 1 && (
                                            <span className="word_in_group_new">
                                              And
                                            </span>
                                          )}
                                        </React.Fragment>
                                      ))}
                                  </div>

                                  <div className="btn_send_filter_maker_page_edit_arabic">
                                    <button
                                      className="btn_save_items_result_body_filter_maker_information_header_down"
                                      onClick={handleSaveEditFilter}
                                    >
                                      حفظ
                                    </button>
                                    <button
                                      onClick={handleReturnClickEdit}
                                      className="btn_cancel_items_result_body_filter_maker_information_header_down"
                                    >
                                      إلغاء
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div className="input_container_result_body_filter_maker_information_filter_add en">
                                  <div className="rows_input_container_result_body_filter_maker_information_filter_add en">
                                    <div className="words_input_group_header_filter_maker_add_items">
                                      <div className="input_group_header_filter_maker_add">
                                        <input
                                          name="filter_name"
                                          placeholder="Enter Name"
                                          type="text"
                                          id="filter_name_input_edit"
                                        />
                                        <span>Name Filter</span>
                                      </div>
                                    </div>

                                    <div className="words_input_group_header_filter_maker_add_items">
                                      <button
                                        onClick={handleAddWordEdit}
                                        className="button__submit_input_group_header_filter_maker_add_input tooltip_add_filter"
                                      >
                                        <CheckIcon />
                                        <span className="tooltip_text_add_filter_page">
                                          Press Enter button key
                                        </span>
                                      </button>

                                      <div className="input_group_header_filter_maker_add">
                                        <input
                                          // required
                                          value={inputValueEdit}
                                          placeholder="Enter Word"
                                          onChange={handleInputChangeEdit}
                                          onKeyPress={handleKeyPress}
                                          type="text"
                                        />
                                        <span>Words</span>
                                      </div>
                                    </div>

                                    <div className="dropdown_input_container_result_body_filter_maker_information_filter_add">
                                      <div
                                        className="dropdown_button_input_container_result_body_filter_maker_information_filter_add en"
                                        onClick={() =>
                                          setMenuOpenEdit(!isMenuOpenEdit)
                                        }
                                      >
                                        <span
                                          className={`arrow_filter_maker_menu ${
                                            isMenuOpenEdit ? "up" : "down"
                                          }`}
                                        >
                                          <KeyboardArrowDownIcon id="arrow_down_menu_filter_maker en" />
                                        </span>

                                        {filterTypeEdit === "and"
                                          ? "And"
                                          : filterTypeEdit === "or"
                                          ? "Or"
                                          : "Not"}
                                      </div>
                                      {isMenuOpenEdit && (
                                        <div className="dropdown_menu_filter_maker_menu en">
                                          <div
                                            onClick={() =>
                                              handleFilterChangeEdit("and")
                                            }
                                          >
                                            And
                                          </div>
                                          <div
                                            onClick={() =>
                                              handleFilterChangeEdit("or")
                                            }
                                          >
                                            Or
                                          </div>
                                          <div
                                            onClick={() =>
                                              handleFilterChangeEdit("no")
                                            }
                                          >
                                            Not
                                          </div>
                                        </div>
                                      )}
                                    </div>

                                    <button
                                      className="button_input_group_filter_maker_page_add"
                                      onClick={handleGrouping}
                                    >
                                      ADD Group
                                    </button>
                                  </div>

                                  <div className="rows_input_container_result_body_filter_maker_information_filter_add en">
                                    <div className="word_list_filter_maker_page en">
                                      {filteredWordsEdit()}
                                    </div>
                                  </div>
                                  <div className="grouped_words_filter_maker_page en">
                                    {selectedFilter.filter_text
                                      .split(" - ")
                                      .map((group, groupIndex, groups) => (
                                        <React.Fragment key={groupIndex}>
                                          <div className="filter_type_filter_maker_page_word_list_group">
                                            <button
                                              // id="btn_add_filter_header_filter_page_red"
                                              className="btn_delete_filtered_word_box_filter_maker_page_group"
                                              onClick={() =>
                                                handleRemoveGroupEdit(
                                                  groupIndex
                                                )
                                              }
                                            >
                                              <DeleteOutlineIcon />
                                            </button>
                                            {group
                                              .split(/&|\||!/)
                                              .map((word, wordIndex, arr) => {
                                                const groupFilterType =
                                                  group.includes("&")
                                                    ? "And"
                                                    : group.includes("|")
                                                    ? "Or"
                                                    : "Not";
                                                return (
                                                  <div
                                                    key={wordIndex}
                                                    className="word_in_group"
                                                  >
                                                    <div className="filter_type_filter_maker_page_word_new">
                                                      {word}
                                                    </div>
                                                    {wordIndex <
                                                      arr.length - 1 && (
                                                      <span className="filter_type_filter_maker_page">
                                                        {groupFilterType}
                                                      </span>
                                                    )}
                                                  </div>
                                                );
                                              })}
                                          </div>
                                          {groupIndex < groups.length - 1 && (
                                            <span className="word_in_group_new">
                                              And
                                            </span>
                                          )}
                                        </React.Fragment>
                                      ))}
                                  </div>
                                  <div className="btn_send_filter_maker_page_edit">
                                    <button
                                      className="btn_save_items_result_body_filter_maker_information_header_down en"
                                      onClick={handleSaveEditFilter}
                                    >
                                      Save
                                    </button>
                                    <button
                                      onClick={handleReturnClickEdit}
                                      className="btn_cancel_items_result_body_filter_maker_information_header_down"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            <div className="simple_filter_page_grouped_words_filter_maker_page">
                              <div className="grouped_words_filter_maker_page_simple">
                                {language === "ar" ? (
                                  <div className="grouped_words_filter_maker_page">
                                    {selectedFilter.filter_text
                                      .split(" - ")
                                      .map((group, groupIndex, groups) => (
                                        <React.Fragment key={groupIndex}>
                                          <div className="group_grouped_words_filter_maker_page_detail">
                                            {group
                                              .split(/&|\||!/)
                                              .map((word, wordIndex, arr) => {
                                                const groupFilterType =
                                                  group.includes("&")
                                                    ? "and"
                                                    : group.includes("|")
                                                    ? "or"
                                                    : "not";
                                                return (
                                                  <div
                                                    key={wordIndex}
                                                    className="word_in_group"
                                                  >
                                                    <div className="filter_type_filter_maker_page_word_detail">
                                                      {word}
                                                    </div>
                                                    {wordIndex <
                                                      arr.length - 1 && (
                                                      <span className="filter_type_filter_maker_page_detail">
                                                        {groupFilterType}
                                                      </span>
                                                    )}
                                                  </div>
                                                );
                                              })}
                                          </div>
                                          {groupIndex < groups.length - 1 && (
                                            <span className="word_in_group_new">
                                              And
                                            </span>
                                          )}
                                        </React.Fragment>
                                      ))}
                                  </div>
                                ) : (
                                  <div className="grouped_words_filter_maker_page">
                                    {selectedFilter.filter_text
                                      .split(" - ")
                                      .map((group, groupIndex, groups) => (
                                        <React.Fragment key={groupIndex}>
                                          <div className="group_grouped_words_filter_maker_page_detail">
                                            {group
                                              .split(/&|\||!/)
                                              .map((word, wordIndex, arr) => {
                                                const groupFilterType =
                                                  group.includes("&")
                                                    ? "And"
                                                    : group.includes("|")
                                                    ? "Or"
                                                    : "Not";
                                                return (
                                                  <div
                                                    key={wordIndex}
                                                    className="word_in_group"
                                                  >
                                                    <div className="filter_type_filter_maker_page_word_detail">
                                                      {word}
                                                    </div>
                                                    {wordIndex <
                                                      arr.length - 1 && (
                                                      <span className="filter_type_filter_maker_page_detail">
                                                        {groupFilterType}
                                                      </span>
                                                    )}
                                                  </div>
                                                );
                                              })}
                                          </div>
                                          {groupIndex < groups.length - 1 && (
                                            <span className="word_in_group_new">
                                              And
                                            </span>
                                          )}
                                        </React.Fragment>
                                      ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterMaker;
